var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"loading":_vm.loading,"loading-text":_vm.$t('common.loadingData'),"no-data-text":_vm.$t('common.noData')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-4",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('pushNotifications.pushNotifications')))]),_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"small":"","fab":"","depressed":"","color":"primary","to":"/push-notifications/create"}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('push-notifications-list-filter',{attrs:{"apply-filters":_vm.applyFilters,"applied-filters":_vm.filters}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(item.name,50))+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("truncate")(item.title[_vm.currentUser.lang],50)))])]}},{key:"item.body",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("truncate")(item.body[_vm.currentUser.lang],50)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status))])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(item.createdBy,20))+" ")]}},{key:"item.sentBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(item.sentBy,20))+" ")]}},{key:"item.lastSentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.lastSentDate))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","fab":"","depressed":"","color":"primary","to":{ name: 'edit-push-notification', params: { id: item.id } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","fab":"","depressed":"","color":"primary","disabled":item.status === _vm.pushNotificationStatuses.inProgress},on:{"click":function($event){return _vm.sendNotification(item)}}},[_c('v-icon',[_vm._v("mdi-send")])],1),(_vm.canDelete)?_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","fab":"","depressed":"","color":"error"},on:{"click":function($event){return _vm.deleteNotification(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }