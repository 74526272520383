<template>
  <fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="loading"
      :loading-text="$t('common.loadingData')"
      :no-data-text="$t('common.noData')"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-4">
          <v-toolbar-title>{{
            $t('pushNotifications.pushNotifications')
          }}</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn
            small
            fab
            depressed
            color="primary"
            to="/push-notifications/create"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <push-notifications-list-filter
          :apply-filters="applyFilters"
          :applied-filters="filters"
        ></push-notifications-list-filter>
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.name | truncate(50) }}
      </template>
      <template v-slot:item.title="{ item }">
        <span>{{ item.title[currentUser.lang] | truncate(50) }}</span>
      </template>
      <template v-slot:item.body="{ item }">
        <span>{{ item.body[currentUser.lang] | truncate(50) }}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <span>{{ item.status }}</span>
      </template>
      <template v-slot:item.createdBy="{ item }">
        {{ item.createdBy | truncate(20) }}
      </template>
      <template v-slot:item.sentBy="{ item }">
        {{ item.sentBy | truncate(20) }}
      </template>
      <template v-slot:item.lastSentDate="{ item }">
        {{ item.lastSentDate | formatDate }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          x-small
          fab
          depressed
          color="primary"
          class="mr-1"
          :to="{ name: 'edit-push-notification', params: { id: item.id } }"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          x-small
          fab
          depressed
          color="primary"
          class="mr-1"
          @click="sendNotification(item)"
          :disabled="item.status === pushNotificationStatuses.inProgress"
        >
          <v-icon>mdi-send</v-icon>
        </v-btn>
        <v-btn
          v-if="canDelete"
          x-small
          fab
          depressed
          color="error"
          class="mr-1"
          @click="deleteNotification(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import PushNotificationsListFilter from './PushNotificationsListFilter';
import ConfirmModal from '../../shared/components/ConfirmModal';
import listItemsMixin from '../../shared/mixins/list.items.mixin';
import httpService from '../services/push.notifications.http.service';
import pushNotificationStatuses from '../../../enums/push.notification.statuses';
import resources from '../../../enums/resources';
import aclMixin from '../../shared/mixins/acl.mixin';

export default {
  name: 'push-notifications-list',
  components: {
    PushNotificationsListFilter
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  mixins: [aclMixin, listItemsMixin],
  data() {
    return {
      feature: resources.gamPushNotifications,
      items: [],
      totalItems: 0,
      loading: true,
      pushNotificationStatuses,
      headers: [
        {
          text: this.$i18n.t('common.name'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$i18n.t('common.title'),
          value: 'title',
          sortable: false
        },
        {
          text: this.$i18n.t('common.text'),
          value: 'body',
          sortable: false
        },
        {
          text: this.$i18n.t('common.status'),
          value: 'status',
          sortable: false
        },
        {
          text: this.$i18n.t('common.createdBy'),
          value: 'createdBy',
          sortable: false
        },
        {
          text: this.$i18n.t('common.sentBy'),
          value: 'sentBy',
          sortable: false
        },
        {
          text: this.$i18n.t('common.lastSentDate'),
          value: 'lastSentDate',
          sortable: false
        },
        {
          text: this.$i18n.t('common.actions'),
          value: 'action',
          sortable: false
        }
      ]
    };
  },
  methods: {
    async getItems(params) {
      this.loading = true;

      const { result } = await httpService.index(params);

      this.items = result.items;
      this.totalItems = result.totalItems;

      this.loading = false;
    },
    async sendNotification(notification) {
      if (notification.status === pushNotificationStatuses.inProgress) {
        this.$notify({
          type: 'warning',
          text: this.$i18n.t(
            'pushNotifications.cannotSendInProgressNotification'
          )
        });

        return;
      }

      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('pushNotifications.sendConfirmText')
      });

      if (!confirmed) return;

      const { result } = await httpService.send(notification.id);

      const index = this.items.findIndex(n => n.id === notification.id);

      this.items.splice(index, 1, {
        ...this.items[index],
        ...result,
        sentBy: this.currentUser.email
      });
    },
    async deleteNotification(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('pushNotifications.deleteConfirmText')
      });

      if (!confirmed) return;

      await httpService.del(id);

      this.resetList(false);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('pushNotifications.deleteSuccess')
      });
    }
  }
};
</script>
